import React, { useState } from "react"
import IconArrow from "../images/down-arrow.svg"

const CompactFileDownload = ({ data }) => {
  const [current, setCurrent] = useState(0)

  if (!data) return null
  return (
    <div className="columns">
      <div className="column">
        <div className="select big red">
          <select onChange={e => setCurrent(e.target.value)} value={current}>
            {data.map((category, index) => {
              return <option value={index}>{category.category}</option>
            })}
          </select>
          <IconArrow />
        </div>
      </div>
      <div className="column">
        <div className="select big red">
          <select
            onChange={e =>
              window.open(e.target.value, "_blank") ||
              window.location.replace(e.target.value)
            }
          >
            <option>Choose...</option>
            {data[current]?.files?.map((file, index) => {
              return (
                <option value={file?.file?.localFile?.publicURL} key={index}>
                  {file?.name}
                </option>
              )
            })}
          </select>
          <IconArrow />
        </div>
      </div>
    </div>
  )
}

export default CompactFileDownload
