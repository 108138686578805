import { useState, useEffect } from "react"

const useWindowDimensions = () => {
  const isSSR = typeof window === "undefined"

  const [windowDimensions, setWindowDimensions] = useState({
    width: isSSR ? null : window.innerWidth,
    height: isSSR ? null : window.innerHeight,
  })

  useEffect(() => {
    if (typeof window === "undefined") return

    function handleResize() {
      setWindowDimensions({
        width: isSSR ? null : window.innerWidth,
        height: isSSR ? null : window.innerHeight,
      })
    }

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  return windowDimensions
}

export default useWindowDimensions
