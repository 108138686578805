import React from "react"
import PdfIcon from "../images/pdf.svg"

const DownloadSection = ({ data }) => {
  if (!data?.files) return null
  return (
    <div className="download-section">
      <div className="container">
        {data.files.map(file => {
          if (file.separator) {
            return <p className="custom-title-5">{file.title}</p>
          } else {
            return (
              <a href={file.pdf?.localFile?.publicURL} target="_blank" className="file">
                <p className="file-name">{file.name}</p>
                <div className="file-size">
                  {(file.pdf?.localFile?.size / 1048576).toFixed(2) + "MB"}
                  <PdfIcon />
                </div>
              </a>
            )
          }
        })}
      </div>
    </div>
  )
}

export default DownloadSection