import React from "react"
import Img from "gatsby-image"
import BannerVideo from "./banner-video"

const Banner = data => {
  const banner = data.banner
  const desktop = banner.media?.desktop
  const mobile = banner.media?.mobile

  if (!desktop.poster) return null

  return (
    <div className="banner">
      <Img
        fluid={desktop.poster?.localFile?.childImageSharp?.fluid}
        className="hide-on-mobile"
        fadeIn={false}
      />
      <Img
        fluid={
          mobile.poster?.localFile?.childImageSharp?.fluid
            ? mobile.poster.localFile.childImageSharp.fluid
            : desktop.poster?.localFile?.childImageSharp?.fluid
        }
        loading="eager"
        fadeIn={false}
        className="hide-desktop"
      />
      <div className="video-wrapper">
        <BannerVideo banner={banner} />
      </div>
      <div class="container">
        <div className="banner-overlay">
          <div className="banner-text">
            <h1 className="banner-text_title">{banner.content.title}</h1>
            <h3 className="banner-text_desc">{banner.content.description}</h3>
          {(banner?.content?.extraTitle ||
            banner?.content?.extraDescription) && (
            <div className="banner-text_extra">
              {banner?.content?.extraTitle && (
                <h3>{banner.content.extraTitle}</h3>
              )}
              {banner?.content?.extraDescription && (
                <p>{banner.content.extraDescription}</p>
              )}
            </div>
          )}
            {banner.content?.buttonLink && banner.content?.buttonTitle && (
              <a className="new-button" href={banner.content.buttonLink}>
                {banner.content.buttonTitle}
              </a>
            )}
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default Banner
