import React, { useState, useRef } from "react"
import useWindowDimensions from "../hooks/useWindowDimensions"
import PlayButton from "../images/play-button.svg"

const BannerVideo = ({ banner }) => {
  const { width } = useWindowDimensions()
  const [showPlayButton, togglePlayButton] = useState(!banner.autoplay)
  const video = useRef(null)
  const desktop = banner.media?.desktop
  const mobile = banner.media?.mobile
  if (!width) return null
  if (width > 640 && !desktop.mp4) return null
  if (width <= 640 && mobile.poster && !mobile.mp4) return null
  return (
    <>
      <video
        autoPlay={banner.autoplay}
        loop
        muted
        playsInline={banner.autoplay}
        ref={video}
      >
        <source
          src={
            width <= 640 && mobile.mp4
              ? mobile.mp4?.localFile?.publicURL
              : desktop.mp4?.localFile?.publicURL
          }
          type="video/mp4"
        />
        {desktop.webm && width > 640 && (
          <source src={desktop.webm?.localFile?.publicURL} type="video/webm" />
        )}
        {mobile.webm && width <= 640 && (
          <source src={mobile.webm?.localFile?.publicURL} type="video/webm" />
        )}
      </video>
      {showPlayButton && (
        <div
          role="button"
          className="play-button"
          tabIndex={0}
          onKeyDown={ev => {
            if (ev.key === "enter" && video.current) {
              video.current.play()
              togglePlayButton(false)
            }
          }}
          onClick={() => {
            if (video.current) {
              video.current.play()
              togglePlayButton(false)
            }
          }}
        >
          <PlayButton />
        </div>
      )}
    </>
  )
}

export default BannerVideo
