import React from "react"
import { ProcessNodeDefinitions, Parser } from "html-to-react"
import CompactFileDownload from "./compact-file-download"
import loadable from "@loadable/component"

const DynamicZones = ({ zones, menu }) => {
  if (!zones) return null

  return (
    <>
      {zones.map((zone, index) => {
        const css_class = zone.settings?.cssClass || ""
        const fullWidthClass = zone.settings?.fullWidth ? "full-width" : ""
        const parallaxClass = zone.settings?.parallax ? "parallax" : ""

        const file_download = zone.compactFileDownload

        const isValidNode = () => true
        const processNodeDefinitions = new ProcessNodeDefinitions(React)
        const processingInstructions = [
          {
            replaceChildren: false,
            shouldProcessNode: node => {
              return node.name === "div" && "data-contact-form" in node.attribs
            },
            processNode: function (node, children, index) {
              const ContactForm = loadable(() =>
                import("./shortcodes/contact-form")
              )
              return <ContactForm id={node.attribs["data-contact-form"]} />
            },
          },
          {
            replaceChildren: false,
            shouldProcessNode: node => {
              return node.name === "div" && "data-events" in node.attribs
            },
            processNode: function (node, children, index) {
              const RecentEvents = loadable(() =>
                import("./shortcodes/recent-events")
              )
              return (
                <RecentEvents
                  style={node.attribs["style"]}
                  limit={node.attribs["limit"]}
                  exclude={node.attribs["exclude"]}
                />
              )
            },
          },
          {
            replaceChildren: false,
            shouldProcessNode: node => {
              return node.name === "div" && "data-posts" in node.attribs
            },
            processNode: function (node, children, index) {
              const RecentPosts = loadable(() =>
                import("./shortcodes/recent-posts")
              )
              return (
                <RecentPosts
                  style={node.attribs["style"]}
                  limit={node.attribs["limit"]}
                  exclude={node.attribs["exclude"]}
                />
              )
            },
          },
          {
            replaceChildren: false,
            shouldProcessNode: node => {
              return node.name === "div" && "data-gallery" in node.attribs
            },
            processNode: function (node, children, index) {
              const Gallery = loadable(() => import("./shortcodes/gallery"))
              return <Gallery id={node.attribs["data-gallery"]} />
            },
          },
          {
            // Anything else
            shouldProcessNode: function (node) {
              return true
            },
            processNode: processNodeDefinitions.processDefaultNode,
          },
        ]
        return (
          <div
            className={`${css_class} ${fullWidthClass} ${parallaxClass} dynamic-zone`}
            key={index}
            style={{
              backgroundColor: zone.settings?.backgroundColor || "",
              backgroundImage:
                zone.settings?.backgroundImage &&
                `url(${zone.settings?.backgroundImage?.localFile?.childImageSharp?.fluid?.srcWebp})`,
            }}
          >
            {file_download ? (
              <CompactFileDownload data={file_download} />
            ) : (
              new Parser().parseWithInstructions(
                zone.html,
                isValidNode,
                processingInstructions
              )
            )}
          </div>
        )
      })}
    </>
  )
}

export default DynamicZones
