import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import DynamicZones from "../components/dynamic-zones"
import DownloadSection from "../components/download-section"
import Seo from "../components/seo"

const Page = ({ data }) => {
  const page = data.wpPage
  const seo = page.seo
  const lang = page.acfSeo?.language
  const hide = page.acfSeo?.noindexNofollow
  const uri = page.uri

  return (
    <Layout>
      <Banner banner={page.acfBanner} />
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        language={lang}
        canonical={seo.canonical}
        pageUri={uri}
        ogTitle={seo.opengraphTitle}
        ogDescription={seo.opengraphDescription}
        ogImage={seo.opengraphImage?.localFile?.childImageSharp?.fixed?.src}
        twitterTitle={seo.twitterTitle}
        twitterDescription={seo.twitterDescription}
        twitterImage={seo.twitterImage?.localFile?.childImageSharp?.fixed?.src}
        hide={hide}
      />
      <DynamicZones
        zones={page.acfContent.dynamicZone}
        menu={page.acfContent.stickyMenu}
      />
      <DownloadSection data={page.acfDownload} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPage(id: { eq: $id }) {
      uri
      title
      acfSeo {
        language
        noindexNofollow
      }
      seo {
        title
        canonical
        metaDesc
        breadcrumbs {
          url
          text
        }
        twitterDescription
        twitterTitle
        twitterImage {
          localFile {
            childImageSharp {
              fixed(width: 800, height: 418) {
                src
              }
            }
          }
        }
        opengraphTitle
        opengraphDescription
        opengraphImage {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
      }
      acfContent {
        stickyMenu
        dynamicZone {
          settings {
            id
            cssClass
            fullWidth
            backgroundImage {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1920) {
                    srcWebp
                  }
                }
              }
            }
            backgroundColor
            parallax
          }
          html
          compactFileDownload {
            category
            files {
              name
              file {
                localFile {
                  publicURL
                }
              }
            }
          }
        }
      }
      acfDownload {
        files {
          separator
          title
          pdf {
            localFile {
              publicURL
              size
            }
          }
          name
        }
      }
      acfBanner {
        content {
          title
          description
          buttonTitle
          buttonLink
          extraTitle
          extraDescription
        }
        autoplay
        media {
          mobile {
            webm {
              localFile {
                publicURL
              }
            }
            mp4 {
              localFile {
                publicURL
              }
            }
            poster {
              localFile {
                publicURL
                childImageSharp {
                  fluid(maxWidth: 414) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                  }
                }
              }
            }
          }
          desktop {
            webm {
              localFile {
                publicURL
              }
            }
            mp4 {
              localFile {
                publicURL
              }
            }
            poster {
              localFile {
                publicURL
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1600) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default Page
